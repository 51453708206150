import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { getVacancyIcon } from '../../../utils/vacancy';
import { usePathPrefix } from '../../../constants/hooks';
import { t } from '../../../i18n';
import { VacancyContact } from '../VacancyContact';
import { TeamContactField } from '../../team/TeamContactField';
import { Button } from '../../main/Button';
import { AttachInput, AttachInputValue } from '../../inputs/AttachInput';
import { sendVacancyFeedback } from '../utils/api';
import { IPositionsQueryEdge } from '../../../queries/positions/types';
import { TLink } from '../../../i18n/TLink';
import { Filler } from '../../common/Filler';
import formInputs from './constant';
import { TextInputWithIcon } from '../../common/TextInputWithIcon';
import { OopsPanel } from '../../common/OopsPanel';
import { getFromUrl } from '../../../utils/getFromUrl';
import {
  fileTypeValidator,
  fileValidatorAfterSubmit,
} from '../../../utils/fileTypeValidator';

interface IProps {
  showSuccess: () => void;
  contacts: Record<string, string>;
  position: IPositionsQueryEdge['node'];
}

const VacancyPageContent: FC<IProps> = ({
  showSuccess,
  contacts,
  position: {
    frontmatter: { quality, title, business, type },
    html,
  },
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const formName = 'order_form_vacancy';

  const prefix = usePathPrefix();
  const [inputsState, setInputsState] = useState<Record<string, string>>({});
  const { name, phone, email, from } = inputsState;
  const [file, setFile] = useState<AttachInputValue>({
    name: '',
    content: null,
  });
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [errorFile, setErrorFile] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [disabled, setDisabled] = useState(false);

  const path = getFromUrl();

  const validateFields = useCallback(() => {
    const validation = {
      name: !name || name.length <= 2,
      email: !email || email.length <= 2,
      phone: !phone || phone.length <= 17,
      file: !file.content || !fileTypeValidator(file),
    };

    fileValidatorAfterSubmit(validation.file, file, setErrorFile);
    setErrors(validation);

    return !Object.values(validation).some((field) => field);
  }, [name, phone, email, file, setErrors]);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const nameInput = e.target.name;
    const { value } = e.target;
    if (!errors[name]) {
      setErrors((prevState) => ({ ...prevState, [nameInput]: false }));
    }
    setInputsState((prevState) => ({
      ...prevState,
      [nameInput]: value,
      from: `/${path}`,
    }));
  };

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      if (!validateFields()) return;
      if (!executeRecaptcha) return;
      const captcha = await executeRecaptcha(formName);

      setDisabled(true);

      try {
        const res = await sendVacancyFeedback({
          email,
          name,
          captcha,
          phone,
          file: file.content || new ArrayBuffer(0),
          filename: file.name,
          vacancy: [quality, business, title].filter((el) => el).join(' '),
          from,
        });

        if (res) {
          setInputsState({});
          setFile({
            name: '',
            content: null,
          });
          showSuccess();
          setIsSuccess(true);
        }
      } catch (e) {
        const error = e as AxiosError;
        setErrorMessage(error.response?.data.error);
      } finally {
        setDisabled(false);
      }
    },
    [validateFields, email, name, phone, file, errorMessage, showSuccess]
  );

  useEffect(() => {
    if (errorMessage && errorMessage.length > 0) {
      setErrorMessage('');
    }
    if (!errors[name]) {
      setDisabled(false);
    }
    if (file.content ?? file.name) {
      setErrorFile('');
    }
  }, [name, phone, email, file]);

  return (
    <>
      <OopsPanel isOpen={Boolean(errorMessage)} errorMessage={errorMessage} />
      <div className={classNames(styles.wrap)}>
        <div className={styles.header}>
          <div className={classNames(styles.header__content, 'content')}>
            <div
              className={styles.thumb}
              style={{
                backgroundImage: `url('${prefix}${getVacancyIcon(type)}')`,
              }}
            />

            <div className={styles.info__text}>
              <div className={styles.info__quality}>
                <TLink to="/vacancies">{t('vacancies.we_need')}</TLink>

                {' / '}
                {title}
                {!!quality && ` (${quality})`}
              </div>

              <div className={styles.info__type}>
                <div className={styles.info__title}>{title}</div>
              </div>

              <div className={styles.info__business}>{business}</div>
            </div>
          </div>
        </div>

        <div className="content">
          <div
            className={styles.info__html}
            dangerouslySetInnerHTML={{ __html: html }}
          />

          <div className={styles.contacts}>
            <VacancyContact
              title="contacts.hr_name"
              value={contacts.name}
              icon={`${prefix}/vacancy/name.svg`}
            />

            <VacancyContact
              title="contacts.email"
              value={contacts.email}
              href={`mailto:${contacts.email}`}
              icon={`${prefix}/vacancy/email.svg`}
            />

            <VacancyContact
              title="contacts.phone"
              value={contacts.phone}
              href={`tel://${contacts.phone}`}
              icon={`${prefix}/vacancy/phone.svg`}
            />

            <div className={styles.contacts__rest}>
              <TeamContactField icon="linkedin">
                {contacts.linkedin}
              </TeamContactField>

              <TeamContactField icon="telegram">
                {contacts.telegram}
              </TeamContactField>

              <TeamContactField icon="whatsapp">
                {contacts.whatsapp}
              </TeamContactField>
            </div>
          </div>

          <div className={styles.inputs_title}>{t('vacancies.page.title')}</div>

          <form onSubmit={onSubmit} id={formName}>
            <div className={styles.form_input}>
              {formInputs.map(({ nameInput, label, icon }) => (
                <TextInputWithIcon
                  name={nameInput}
                  value={inputsState[nameInput] ?? ''}
                  handler={onChange}
                  label={label}
                  icon={`${prefix}${icon}`}
                  hasError={errors[nameInput]}
                  key={nameInput}
                  isSuccess={isSuccess}
                  id={`${path}-${nameInput}`}
                />
              ))}
            </div>
            <div className={styles.fields}>
              <AttachInput value={file} handler={setFile} error={errorFile} />
              <Filler />
            </div>
            <input type="hidden" name="from" id="from" value={path} />
            <div className={styles.submit}>
              <Button isLoading={disabled} disabled={disabled}>
                {t('vacancies.page.send_request')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export { VacancyPageContent };
